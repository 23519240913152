import $ from 'jquery'
window.$ = window.jQuery = window.jquery = $

//= require tinymce-jquery

// Ladda (loading-state buttons)
import * as Ladda from 'ladda';

// Cocoon for nested forms
import '@nathanvda/cocoon';

import 'jquery-ui/ui/widgets/sortable';

// require jquery_ujs
import 'bootstrap';
import 'raphael';
import 'colorwheel';
import 'datatables';
import '../../src/js/jquery.fileupload';

window.App || (window.App = {});

App.loadMixpanel = function() {
  if (typeof(mixpanel) !== 'object') {
    console.log('Loading Mixpanel', mixpanelToken);
    (function(e,b){if(!b.__SV){var a,f,i,g;window.mixpanel=b;b._i=[];b.init=function(a,e,d){function f(b,h){var a=h.split(".");2==a.length&&(b=b[a[0]],h=a[1]);b[h]=function(){b.push([h].concat(Array.prototype.slice.call(arguments,0)))}}var c=b;"undefined"!==typeof d?c=b[d]=[]:d="mixpanel";c.people=c.people||[];c.toString=function(b){var a="mixpanel";"mixpanel"!==d&&(a+="."+d);b||(a+=" (stub)");return a};c.people.toString=function(){return c.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
    for(g=0;g<i.length;g++)f(c,i[g]);b._i.push([a,e,d])};b.__SV=1.2;a=e.createElement("script");a.type="text/javascript";a.async=!0;a.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?MIXPANEL_CUSTOM_LIB_URL:"file:"===e.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";f=e.getElementsByTagName("script")[0];f.parentNode.insertBefore(a,f)}})(document,window.mixpanel||[]);
    mixpanel.init(mixpanelToken);
  }
}

App.init = function() {
  $("[data-toggle='tooltip']").tooltip();
  $("[data-toggle='popover']").popover();
  $("a[href^='http://']").attr("target","_blank");

  var ids = [];
  $('#album_upload').fileupload({
    progressall: function(e, data) {
      $('#photos-upload-status').html('Envoi en cours... Veuillez patienter.');
      $('.form-actions .btn-success').prop('disabled', 'disabled');
    },
    done: function(e, data) {
      var result = JSON.parse(data.result);
      ids.push(result.picture_id);
      $('#photos_ids').val(ids);
      $.ajax({
        type: 'GET',
        url: '/admin/estates/' + result.estate_id + '/photos',
        success: function(html) {
          $('#photos').html(html);
        }
      });
    }
  });
};

$(document).on('turbolinks:load', function() {
  App.loadMixpanel();
  return App.init();
});
